<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Code"
          v-model="record.Code"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Description"
          v-model="record.Name"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row dense>
      <v-tabs
        background-color="accent"
        color="white"
      >
        <v-tab>Defaults</v-tab>

        <v-tab-item>
          <v-row>
            <v-col
              cols="4"
              v-if="distributionRules1.length > 0"
            >
              <v-autocomplete
                outlined
                dense
                v-model="record.CogsOcrCod"
                :items="distributionRules1"
                :item-text="item => item.OcrCode +'  -  '+ item.OcrName"
                item-value="OcrCode"
                :label="Dimension1"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="4"
              v-if="distributionRules2.length > 0"
            >
              <v-autocomplete
                outlined
                dense
                v-model="record.CogsOcrCo2"
                :items="distributionRules2"
                :item-text="item => item.OcrCode +'  -  '+ item.OcrName"
                item-value="OcrCode"
                :label="Dimension2"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="4"
              v-if="distributionRules3.length > 0"
            >
              <v-autocomplete
                outlined
                dense
                v-model="record.CogsOcrCo3"
                :items="distributionRules3"
                :item-text="item => item.OcrCode +'  -  '+ item.OcrName"
                item-value="OcrCode"
                :label="Dimension3"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="4"
              v-if="distributionRules4.length > 0"
            >
              <v-autocomplete
                outlined
                dense
                v-model="record.CogsOcrCo4"
                :items="distributionRules4"
                :item-text="item => item.OcrCode +'  -  '+ item.OcrName"
                item-value="OcrCode"
                :label="Dimension4"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="4"
              v-if="distributionRules5.length > 0"
            >
              <v-autocomplete
                outlined
                dense
                v-model="record.CogsOcrCo5"
                :items="distributionRules5"
                :item-text="item => item.OcrCode +'  -  '+ item.OcrName"
                item-value="OcrCode"
                :label="Dimension5"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- <v-tab-item>general</v-tab-item>
        <v-tab-item>display</v-tab-item>
        <v-tab-item>print</v-tab-item>
        <v-tab-item>credit cards</v-tab-item> -->
      </v-tabs>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-btn
          color="accent"
          @click="sendData"
          :loading="loader"
        >
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    record: {},
    drivers: [],
    loader: false,
    priceLists: [],
    warehouses: [],
    salesEmployees: [],
    itemGroups: [],
    branches: [],
    Dimension1: "",
    Dimension2: "",
    Dimension3: "",
    Dimension4: "",
    Dimension5: "",

    distributionRules1: [],
    distributionRules2: [],
    distributionRules3: [],
    distributionRules4: [],
    distributionRules5: [],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    setDate(date) {
      this.record.date = date;
    },
    sendData() {
      this.loader = true;
      this.$emit("data", this.record);
    },

    getDistributionRules(id) {
      const self = this;
      this.$store
        .dispatch("get", `/distribution-rules?DimCode=${id}`)
        .then((res) => {
          if (id == 1) {
            self.distributionRules1 = res.ResponseData;
            self.Dimension1 = res.ResponseData[0].odim.DimDesc;
          }
          if (id == 2) {
            self.distributionRules2 = res.ResponseData;
            self.Dimension2 = res.ResponseData[0].odim.DimDesc;
          }
          if (id == 3) {
            self.distributionRules3 = res.ResponseData;
            self.Dimension3 = res.ResponseData[0].odim.DimDesc;
          }
          if (id == 4) {
            self.distributionRules4 = res.ResponseData;
            self.Dimension4 = res.ResponseData[0].odim.DimDesc;
          }
          if (id == 5) {
            self.distributionRules5 = res.ResponseData;
            self.Dimension5 = res.ResponseData[0].odim.DimDesc;
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getDistributionRules(1);
    this.getDistributionRules(2);
    this.getDistributionRules(3);
    this.getDistributionRules(4);
    this.getDistributionRules(5);
  },
};
</script>